<template lang="pug">
v-container(fluid)
  v-row
    v-col(cols="12")
      v-card
        v-tabs(
          v-model="assetTab"
        )
          v-tab(
            :to="{ name: 'assets' }"
            exact
          )
            | {{ $t('assets.all') }}
          v-tab(
            :to="{ name: 'assets', params: { view: 'projects' } }"
            exact
          )
            | {{ $tc('asset-projects.project', 2) }}
        v-tabs-items(
          v-model="assetTab"
        )
          v-tab-item(
            :value="$router.resolve({ name: 'assets' }).href"
          )
            v-card-text
              v-row
                v-col(cols="12" sm="6")
                  v-autocomplete(
                    v-model="assetTypeFilter"
                    :items="getAssetTypes"
                    :label="$t('asset-types.filter')"
                    dense
                    item-value="id"
                    item-text="name"
                    multiple
                    small-chips
                    deletable-chips
                    solo
                    filled
                  )
                    template(v-slot:selection="data")
                      v-chip(
                        v-bind="data.attrs"
                        v-on="data.on"
                        :input-value="data.selected"
                        small
                      )
                        v-icon(left small) {{ data.item.icon }}
                        span {{ data.item.name }}
                v-col(cols="12" sm="6")
                  v-autocomplete(
                    v-model="organizationsFilter"
                    :items="getOrganizations"
                    :label="$t('organizations.filter')"
                    dense
                    item-value="id"
                    item-text="name"
                    multiple
                    small-chips
                    deletable-chips
                    solo
                    filled
                  )
                    template(v-slot:selection="data")
                      v-chip(
                        v-bind="data.attrs"
                        v-on="data.on"
                        :input-value="data.selected"
                        small
                      )
                        v-avatar(small left :color="data.item.color")
                          v-img(:src="data.item.logoSrc" contain)
                        span {{ data.item.name }}
            AssetsTable(:assets="filteredAssets" show-items-per-page)
              template(v-slot:extra-actions="{ item }")
                v-btn(small icon @click.stop="editAsset(item.asset)")
                  v-icon(small)
                    | mdi-pencil-outline
                v-tooltip(
                  top
                )
                  template(v-slot:activator="{ attrs, on }")
                    v-btn(
                      v-bind="attrs"
                      v-on="on"
                      small
                      icon
                      @click.stop="deleteAsset(item)"
                    )
                      v-icon(small)
                        template(v-if="isLoadingAssetDelete") mdi-loading mdi-spin
                        template(v-else) mdi-delete-outline
                  | {{ $t('item.delete', { item: item.name }) }}
            v-card-actions
              v-spacer
              AssetEdit(
                ref="assetsManager"
              )
          v-tab-item(
            :value="$router.resolve({ name: 'assets', params: { view: 'projects' } }).href"
          )
            v-container(fluid)
              AssetProjects
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import AssetEdit from '@/components/assets/AssetEdit.vue';
import AssetsTable from '@/components/assets/AssetsTable.vue';
import AssetProjects from '@/components/assets/AssetProjects.vue';

export default Vue.extend({
  name: 'Assets',
  components: {
    AssetsTable,
    AssetProjects,
    AssetEdit,
  },
  props: {
    view: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      assetTab: null,
    };
  },
  computed: {
    ...mapGetters([
      'getAssets',
      'isLoadingAssetDelete',
      'getOrganizations',
      'getAssetTypes',
    ]),
    assetTypeFilter: {
      set(assetTypes) {
        this.filterUrl({
          assetTypes,
        });
      },
      get() {
        return this.arrayFromUrl('assetTypes');
      },
    },
    organizationsFilter: {
      set(organizations) {
        this.filterUrl({
          organizations,
        });
      },
      get() {
        return this.arrayFromUrl('organizations');
      },
    },
    filteredAssets() {
      return this.getAssets
        .filter((a) => this.assetTypeFilter.length === 0
          || this.assetTypeFilter.some((f) => a.assetTypeId === f))
        .filter((a) => this.organizationsFilter.length === 0
          || this.organizationsFilter.some((f) => a.organizationId === f));
    },
    assetsManager() {
      return this.$refs.assetsManager;
    },
  },
  methods: {
    filterUrl(q) {
      const query = {
        assetTypes: this.assetTypeFilter,
        organizations: this.organizationsFilter,
        ...q,
      };
      return this.$router.push({
        name: this.$route.name,
        query,
      });
    },
    arrayFromUrl(q) {
      let items = this.$route?.query?.[q] ?? [];
      if (!Array.isArray(items)) {
        items = [items];
      }
      return items;
    },
    editAsset(asset) {
      this.assetsManager.editAsset(asset);
    },
    deleteAsset(asset) {
      this.assetsManager.deleteAsset(asset);
    },
  },
});
</script>
