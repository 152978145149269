<template lang="pug">
  ManageObject(
    ref="assetsManager"
    objectText="Systeem"
    objectTitleKey="name"
    :defaultObject="defaultAsset"
    actionAdd="ADD_ASSET"
    actionUpdate="UPDATE_ASSET"
    actionDelete="DELETE_ASSET"
  )
    template(
      v-slot:form="{ edittingObject }"
    )
      v-row
        v-col(cols="12")
          v-text-field(
            v-model="edittingObject.name"
            :label="$t('name')"
            :rules="rules"
            required
          )
          v-text-field(
            v-model="edittingObject.vpnIp"
            :label="$t('hostname-or-vpn-ip')"
            placeholder="vpn.com / x.x.x.x"
          )
          v-text-field(
            v-model="edittingObject.ip"
            :label="$t('hostname-or-ip-within-vpn')"
            placeholder="asset.com / x.x.x.x"
            :hint="$t('add-custom-scheme')"
            persistent-hint
          )
          v-autocomplete(
            v-model="edittingObject.organizationId"
            :label="$tc('organizations.organization', 1)"
            :items="getOrganizations"
            :rules="rules"
            required
            clearable
            item-value="id"
            item-text="name"
          )
          v-autocomplete(
            v-model="edittingObject.assetTypeId"
            :label="$tc('asset-types.type', 1)"
            :items="getAssetTypes"
            :rules="rules"
            required
            clearable
            item-value="id"
            item-text="name"
            :prepend-inner-icon="selectedAssetTypeIcon(edittingObject.assetTypeId)"
          )
            template(v-slot:item="data")
              v-list-item-icon
                v-icon {{ data.item.icon }}
              v-list-item-content
                v-list-item-title {{ data.item.name }}
        v-col(cols="12")
          v-card
            v-subheader {{ $tc('data-connectors', 2) }}
            v-card-text
              | {{ $t('data-connector.manage') }}
            v-expansion-panels(
              v-model="selectedConnector"
              flat
            )
              v-expansion-panel(
                v-for="(connector, i) in edittingObject.connectors"
                :key="`${connector.connectorType}-${i}-list`"
              )
                v-divider
                v-expansion-panel-header
                  | {{ connectors[connector.connectorType].title }}
                v-expansion-panel-content
                  p {{ connectors[connector.connectorType].subtitle }}
                  template(v-if="connector.connectorType === CONNECTOR_TYPE_XWEB")
                    v-text-field.mb-4(
                      v-model="connector.options.url"
                      :rules="rules"
                      :label="$t('data-connector.xweb.host')"
                      :hint="$t('data-connector.xweb.host-hint')"
                      persistent-hint
                    )
                    v-switch(
                      v-model="connector.options.viaVpn"
                      :label="$t('data-connector.via-vpn')"
                      :hint="$t('data-connector.via-vpn-hint')"
                      persistent-hint
                    )
                    v-text-field.mb-4(
                      v-model="connector.options.username"
                      :rules="rules"
                      :label="$t('username')"
                      :hint="$t('data-connector.xweb.username-hint')"
                      persistent-hint
                    )
                    v-text-field.mb-4(
                      v-model="connector.options.password"
                      :rules="rules"
                      :label="$t('password')"
                      :hint="$t('data-connector.xweb.password-hint')"
                      persistent-hint
                      type="password"
                    )
                    v-text-field.mb-4(
                      v-model.number="connector.options.deviceId"
                      :rules="rules"
                      :label="$t('data-connector.xweb.device-id')"
                      :hint="$t('data-connector.xweb.device-id-hint')"
                      persistent-hint
                      type="number"
                      :step="1"
                      :min="1"
                    )
                    v-subheader {{ $t('data-connector.xweb.sensor-mapping') }}
                    v-row(
                      v-for="(sensorTypeMap, stmi) in connector.options.sensorTypeIdMap"
                      :key="stmi"
                    )
                      v-col
                        v-text-field(
                          v-model.number="sensorTypeMap.id"
                          :rules="rules"
                          :label="$t('data-connector.xweb.sensor-id')"
                          persistent-hint
                          type="number"
                          :step="1"
                          :min="1"
                        )
                      v-col
                        v-autocomplete(
                          v-model="sensorTypeMap.sensorTypeId"
                          :label="$tc('data-connector.xweb.sensor-type', 1)"
                          :items="getSensorTypes"
                          clearable
                          item-value="id"
                          item-text="name"
                          persistent-hint
                          :rules="rules"
                          append-outer-icon="mdi-close"
                          @click:append-outer="removeSensorTypeIdMap(connector, stmi)"
                        )
                    v-row
                      v-col
                        v-btn(block color="primary" @click="addSensorTypeIdMap(connector)")
                          v-icon(left) mdi-plus
                          | {{ $t('data-connector.xweb.add-sensor-type-map') }}
                  v-divider.my-4
                  v-btn(
                    text
                    color="error"
                    @click="removeConnector(edittingObject, i)"
                    block
                  )
                    v-icon(left) mdi-delete
                    | {{ $t('item.delete', { item: connector.connectorType }) }}
            v-divider
            v-card-actions
              v-spacer
              v-menu(
                bottom
                left
              )
                template(v-slot:activator="{ on, attrs }")
                  v-btn(
                    color="primary"
                    text
                    v-bind="attrs"
                    v-on="on"
                  )
                    v-icon(left) mdi-plus
                    | {{ $tc('item.add') }}
                v-list
                  v-list-item(
                    v-for="(connector, connectorType) in connectors"
                    :key="connectorType"
                    @click="addConnector(edittingObject, connectorType)"
                    link
                  )
                    v-list-item-icon
                      v-icon(color="primary") mdi-power-plug
                    v-list-item-content
                      v-list-item-title {{ connector.title }}
        v-col(cols="12")
          v-card
            v-subheader {{ $tc('components.component', 2) }}
            v-card-text(v-if="edittingObject.components.length === 0")
              em {{ $t('components.none-found') }}
            v-card-text(v-else)
              v-row(v-for="(c, componentIndex) in edittingObject.components" :key="componentIndex")
                v-col(cols="6")
                  v-text-field(
                    v-model="c.name"
                    :label="$t('components.name')"
                  )
                v-col(cols="6")
                  v-text-field(
                    v-model="c.ip"
                    :label="$t('components.host')"
                    append-outer-icon="mdi-close"
                    @click:append-outer="removeComponent(connector, componentIndex)"
                  )
            v-card-actions
              v-spacer
              v-btn(
                color="primary"
                text
                @click="addComponent(edittingObject)"
              )
                v-icon(left) mdi-plus
                | {{ $tc('item.add') }}
        v-col(cols="12")
          GroupsAccess(
            v-model="edittingObject.groups"
          )
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { CONNECTOR_TYPE_XWEB, CONNECTOR_TYPE_AZUREIOT } from '@/models/data-connector';
import ManageObject from '@/components/misc/ManageObject.vue';
import GroupsAccess from '@/components/form/GroupsAccess.vue';

export default Vue.extend({
  name: 'Assets',
  components: {
    ManageObject,
    GroupsAccess,
  },
  props: {
  },

  data() {
    return {
      defaultComponent: {
        name: '',
        ip: '',
      },
      defaultAsset: {
        name: '',
        ip: '',
        vpnIp: '',
        organizationId: '',
        groups: [],
        components: [],
        connectors: [],
      },
      CONNECTOR_TYPE_XWEB,
      CONNECTOR_TYPE_AZUREIOT,
      connectors: {
        [CONNECTOR_TYPE_XWEB]: {
          title: 'XWeb',
          subtitle: this.$t('data-connector.xweb.hint'),
          defaultObject: {
            connectorType: CONNECTOR_TYPE_XWEB,
            options: {
              url: '',
              viaVpn: false,
              username: '',
              password: '',
              deviceId: 1,
              sensorTypeIdMap: [],
            },
          },
        },
        [CONNECTOR_TYPE_AZUREIOT]: {
          title: this.$t('data-connector.azure-iot.title'),
          subtitle: this.$t('data-connector.azure-iot.hint'),
          defaultObject: {
            connectorType: CONNECTOR_TYPE_AZUREIOT,
            options: {
              inAzure: null,
            },
          },
        },
      },
      selectedConnector: null,
      rules: [
        (v) => !!v || this.$t('validation.mandatory'),
      ],
      notEmptyRule: (v) => v.length > 0 || this.$tc('validation.select-min', 1, { n: 1 }),
    };
  },
  mounted() {
    this.$store.dispatch('GET_SENSOR_TYPES');
  },
  computed: {
    ...mapGetters([
      'getOrganizations',
      'getAssetTypes',
      'getAssetType',
      'getSensorTypes',
    ]),
    assetsManager() {
      return this.$refs.assetsManager;
    },
    edittingAsset() {
      return this.assetsManager?.edittingObject;
    },
  },
  methods: {
    editAsset(asset) {
      this.assetsManager.editObject(asset);
    },
    deleteAsset(asset) {
      this.assetsManager.deleteObject(asset);
    },
    addComponent(edittingObject) {
      return edittingObject.components.push(
        JSON.parse(JSON.stringify(this.defaultComponent)),
      );
    },
    removeComponent(edittingObject, index) {
      edittingObject.components.splice(index, 1);
    },
    selectedAssetTypeIcon(assetTypeId) {
      if (!assetTypeId) return null;
      return this.getAssetType(assetTypeId)?.icon;
    },
    hasConnector(edittingObject, connectorType) {
      return !!edittingObject.connectors.find((c) => c.connectorType === connectorType);
    },
    addConnector(edittingObject, connectorType) {
      return edittingObject.connectors.push(
        JSON.parse(JSON.stringify(this.connectors[connectorType].defaultObject)),
      );
    },
    removeConnector(edittingObject, index) {
      edittingObject.connectors.splice(index, 1);
    },
    addSensorTypeIdMap(connector) {
      connector.options.sensorTypeIdMap.push({
        ...{
          id: 0, sensorTypeId: '',
        },
      });
    },
    removeSensorTypeIdMap(connector, index) {
      connector.options.sensorTypeIdMap.splice(index, 1);
    },
  },
  watch: {
  },
});
</script>
